/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { AppTextLabel } from '../../services/i18nservice';
import './confirmmodal.less';

export const ClassNames = {
    modalWithFooter: "modalwithfooter",
    modalContent: "modalcontent bloc-defaultbg",
    modalFooter: "modalfooter bloc-defaultbg bloc-lightborder"

};
export function ModalWithFooter(props: React.PropsWithChildren<any>) {
    return <div className={ClassNames.modalWithFooter + " " + (props.className || "")}>
        {props.children}
    </div>;
}

export function ModalContent(props: React.PropsWithChildren<any>) {
    return <section className={ClassNames.modalContent + " " + (props.className || "")}>
        {props.children}
    </section>;
}

export function ModalFooter(props: React.PropsWithChildren<any>) {
    return <footer className={ClassNames.modalFooter + " " + (props.className || "")}>
        {props.children}
    </footer>;
}

export interface IConfirmModalProps {
    message: string;
    messageInject?: any;
    enableSkipNextTimeOption?: boolean;
    customComponent?: any;
    onCompleted?: (res: IConfirmModalResult) => void;
}

export interface IConfirmModalResult {
    confirm: boolean;
    skipNextTime?: boolean;
}

interface IConfirmModalState {
    skip?: boolean;
}

export class ConfirmModal extends React.Component<IConfirmModalProps, IConfirmModalState> {
    constructor(props: IConfirmModalProps) {
        super(props);
        this.state = {
        };
    }

    accept = () => {
        this.props.onCompleted({ confirm: true, skipNextTime: this.state.skip });
    };

    decline = () => {
        this.props.onCompleted({ confirm: false });
    };

    renderSkip() {
        if (!this.props.enableSkipNextTimeOption) return null;

        return <div className="status-item">
            <input
                id={"skipnexttimeoption"}
                type="checkbox"
                checked={this.state.skip}
                onChange={(arg) => {
                    this.setState({
                        skip: arg.currentTarget.checked
                    });
                }}
            />
            <AppTextLabel component="label" htmlFor={"skipnexttimeoption"} i18n={"modal.confirm.skipnexttime"} />
        </div>;
    }

    render() {
        return <ModalWithFooter>
            <ModalContent>
                {this.props.customComponent}
                <AppTextLabel i18n={this.props.message} component="p" inject={this.props.messageInject} />
                {this.renderSkip()}
            </ModalContent>
            <ModalFooter>
                <button type="button" className="lightbtn" onClick={this.decline}><AppTextLabel i18n="actions.no" /></button>
                <button type="button" onClick={this.accept}><AppTextLabel i18n="actions.yes" /></button>
            </ModalFooter>
        </ModalWithFooter>;
    }
}
