import type { IPopoverManager } from '@inwink/modals/popovermgr';
import type { PopoverAdvancedOptions } from '@inwink/modals/popover';
import type { Entities } from '@inwink/entities/entities';
import type { States } from '../services';
import { ConfirmModal, IConfirmModalProps, IConfirmModalResult } from '../../components/modals';

export const userMessageActions = {
    showMessage: (
        i18nHelper: Entities.i18nHelper,
        component: any,
        props,
        elt: HTMLElement,
        timeout?: number,
        popovermgr?: IPopoverManager,
        modalClass?: string,
        options?: PopoverAdvancedOptions
    ) => {
        const promiseHandler = {
            complete: null,
            error: null
        };

        const result = new Promise((complete, error) => {
            promiseHandler.complete = complete;
            promiseHandler.error = error;
        });

        return (dispatch) => {
            dispatch({
                type: "USERMESSAGE_SHOW",
                payload: <States.IAppUserMessage>{
                    i18nHelper: i18nHelper,
                    modalClassName: modalClass,
                    component: component,
                    props: props,
                    show: true,
                    parentElt: elt,
                    timeout: timeout,
                    promiseHandler,
                    options: options
                }
            });
            return result;
        };
    },

    hideMessage: () => {
        return (dispatch) => {
            dispatch({
                type: "USERMESSAGE_HIDE",
            });
        };
    },

    showModal: (
        i18nHelper: Entities.i18nHelper,
        component: any,
        title,
        props,
        modalClass?: string,
        elt?: HTMLElement,
        timeout?: number,
        popovermgr?: IPopoverManager,
        options?: PopoverAdvancedOptions,
        noTitle?: boolean
    ) => {
        const promiseHandler = {
            complete: null,
            error: null,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setProps: (_props) => { }
        };

        const result = new Promise((complete, error) => {
            promiseHandler.complete = complete;
            promiseHandler.error = error;
        });

        (result as any).setProps = (_props) => {
            promiseHandler.setProps(_props);
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState?: () => States.IAppState) => {
            dispatch({
                type: "USERMODAL_SHOW",
                payload: <States.IAppUserMessage>{
                    i18nHelper: i18nHelper,
                    component: component,
                    props: props,
                    title: title,
                    modalClassName: modalClass,
                    show: true,
                    parentElt: elt,
                    promiseHandler: promiseHandler,
                    timeout: timeout,
                    popovermgr: popovermgr,
                    options: options,
                    noTitle: noTitle
                }
            });
            return result;
        };
    },

    hideModal: () => {
        return (dispatch) => {
            dispatch({
                type: "USERMODAL_HIDE",
            });
        };
    },

    confirm: (
        i18nHelper: Entities.i18nHelper,
        options: Omit<IConfirmModalProps, 'enableSkipNextTimeOption' | 'onCompleted'> & {
            title: string;
        }
    ) => {
        return (dispatch, getState?: () => States.IAppState): Promise<boolean> => {
            return userMessageActions.showModal(i18nHelper, ConfirmModal, options.title,
                options, "iwconfirmmodal")(dispatch, getState)
                .then((res: IConfirmModalResult) => {
                    return res?.confirm ?? false;
                });
        };
    },

    confirmSkipable: (
        i18nHelper: Entities.i18nHelper,
        options: Omit<IConfirmModalProps, 'onCompleted'> & {
            title: string;
        }
    ) => {
        return (dispatch, getState?: () => States.IAppState): Promise<IConfirmModalResult> => {
            return userMessageActions.showModal(i18nHelper, ConfirmModal, options.title,
                options, "iwconfirmmodal")(dispatch, getState)
                .then((res?: IConfirmModalResult) => {
                    return res;
                });
        };
    }
};
