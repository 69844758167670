import { States } from "@@services/services";
import type { Entities } from "@inwink/entities/entities";
import type { VisualTheme } from "@inwink/entities/visualtheme";

function hasAtLeastOneMenuItemEnabled(itemMenuList) : boolean {
    return itemMenuList?.some((item) => item.id && !item?.disabled);
}

function hasItemInTemplateMenuList(bloc: VisualTheme.IBlocTemplateBase) : boolean {
    return bloc?.content?.some((item) => item?.id === "menuitems" &&
    item?.properties?.items?.length > 0 &&
    hasAtLeastOneMenuItemEnabled(item?.properties?.items));
}

export function hasItemsInMenu(menuTemplate: Entities.IContentTemplate) : boolean {
    return menuTemplate?.config?.blocs?.some(hasItemInTemplateMenuList);
}

export function getItemsPickerThemeClass(state: Partial<States.IAppState>) {
    if (state.event?.eventid) {
        return "event-" + state.event.eventid;
    } else if (state.community?.communityid) {
        return "community-" + state.community.communityid;
    }

    return "";
}